var Handlebars = require("../../../../../../node_modules/.pnpm/handlebars@4.7.7/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <a\n    href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pathname") || (depth0 != null ? lookupProperty(depth0,"pathname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pathname","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":22}}}) : helper)))
    + "\"\n    class='link uriLabel entity-value'\n    resource='"
    + alias4((lookupProperty(helpers,"entityRemoteHref")||(depth0 && lookupProperty(depth0,"entityRemoteHref"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"uri") : depth0),{"name":"entityRemoteHref","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":38}}}))
    + "'\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":9,"column":41}}})) != null ? stack1 : "")
    + "\n    data-label-uri='"
    + alias4(((helper = (helper = lookupProperty(helpers,"uri") || (depth0 != null ? lookupProperty(depth0,"uri") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uri","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":27}}}) : helper)))
    + "'>\n  "
    + alias4(((helper = (helper = lookupProperty(helpers,"alt") || (depth0 != null ? lookupProperty(depth0,"alt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alt","hash":{},"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":11,"column":9}}}) : helper)))
    + "\n  </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "title=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":33}}}) : helper)))
    + "\"";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <span\n    class='uriLabel entity-value'\n    resource='"
    + alias3((lookupProperty(helpers,"entityRemoteHref")||(depth0 && lookupProperty(depth0,"entityRemoteHref"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"uri") : depth0),{"name":"entityRemoteHref","hash":{},"data":data,"loc":{"start":{"line":16,"column":14},"end":{"line":16,"column":38}}}))
    + "'\n    data-label-uri='"
    + alias3(((helper = (helper = lookupProperty(helpers,"uri") || (depth0 != null ? lookupProperty(depth0,"uri") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"uri","hash":{},"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":17,"column":27}}}) : helper)))
    + "'>\n  "
    + alias3(((helper = (helper = lookupProperty(helpers,"alt") || (depth0 != null ? lookupProperty(depth0,"alt") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"alt","hash":{},"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":18,"column":9}}}) : helper)))
    + "\n  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"entityLink") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "");
},"useData":true});