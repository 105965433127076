var Handlebars = require("../../../../../node_modules/.pnpm/handlebars@4.7.7/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"friendAcceptedRequest notification-link\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pathname") || (depth0 != null ? lookupProperty(depth0,"pathname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pathname","hash":{},"data":data,"loc":{"start":{"line":1,"column":57},"end":{"line":1,"column":69}}}) : helper)))
    + "\">\n  <img src=\""
    + alias4((lookupProperty(helpers,"imgSrc")||(depth0 && lookupProperty(depth0,"imgSrc"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"picture") : depth0),48,{"name":"imgSrc","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":33}}}))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":2,"column":40},"end":{"line":2,"column":52}}}) : helper)))
    + "\">\n  <div class=\"info\">\n    <span>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"friend_accepted_request",depth0,{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":51}}})) != null ? stack1 : "")
    + "</span><br>\n    <span class=\"time\">"
    + alias4((lookupProperty(helpers,"timeFromNow")||(depth0 && lookupProperty(depth0,"timeFromNow"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"time") : depth0),{"name":"timeFromNow","hash":{},"data":data,"loc":{"start":{"line":5,"column":23},"end":{"line":5,"column":43}}}))
    + "</span>\n  </div>\n"
    + ((stack1 = container.invokePartial(require("./unread_flag.hbs"),depth0,{"name":"notifications:unread_flag","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"usePartial":true,"useData":true});